import { first, map, Observable, of, switchMap, tap } from 'rxjs';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Injector, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { actionsExecuting } from '@ngxs-labs/actions-executing';

import { InventoryChannelItem } from '@supy/channel-items';
import { Channel } from '@supy/channels';
import {
  DEFAULT_QUANTITY_PRECISION,
  FormComponent,
  getDateInTimeZone,
  getDateWithDaysDifference,
  getRetailerTimeZoneHelperMessage,
  getShiftedDate,
  InputValidators,
  SnackbarService,
  SupplierType,
} from '@supy/common';
import { ConfirmDialogWithIconComponent, DialogService } from '@supy/components';
import { Span } from '@supy/opentelemetry';
import {
  OrdersCreate,
  OrdersGet,
  OrdersPermissionsStrategy,
  OrdersState,
  OrderStatus,
  OrdersUpdate,
  OrderUpdateStatus,
} from '@supy/orders';
import { SettingsState } from '@supy/settings';

import { PlaceOrderService } from '../../services';

interface FormValue {
  readonly comment: string | null;
  readonly deliveryDate: Date;
}

@Component({
  selector: 'supy-review-order',
  templateUrl: './review-order.component.html',
  styleUrls: ['./review-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class ReviewOrderComponent extends FormComponent<FormValue> implements OnInit {
  readonly #store = inject(Store);
  readonly #injector = inject(Injector);
  readonly #snackbar = inject(SnackbarService);
  readonly #dialog = inject(DialogService);
  readonly #placeOrderService = inject(PlaceOrderService);
  readonly #route = inject(ActivatedRoute);
  readonly #routeData = toSignal(this.#route.data);
  readonly #routeQueryParamMap = toSignal(this.#route.queryParamMap);
  readonly #orderId = computed(() => this.#routeQueryParamMap().get('orderId'));
  readonly #isRepeat = computed(() => this.#routeQueryParamMap().get('repeat'));
  readonly #deliveryDateChanges = toSignal(this.getValueChanges('deliveryDate'));
  readonly #tomorrow = computed(() => {
    const tomorrow = getDateInTimeZone(new Date(), this.ianaTimeZone());

    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
  });

  readonly #isOrderLessThanSupplierMinimumLimit = computed(
    () =>
      this.channelItems().reduce<number>((acc, { price, quantity }) => acc + price * (quantity ?? 0), 0) <
      this.channel()?.metadata?.minimumOrderLimit,
  );

  readonly #isOrderAfterSupplierCutOffTime = computed(() => {
    const cutOffTime = this.channel()?.metadata?.cutOffTime;
    const deliveryDate = this.getDateInRetailerTimeZone(new Date(this.#deliveryDateChanges()));
    const tomorrow = this.#tomorrow();

    tomorrow.setHours(0, 0, 0, 0);
    deliveryDate.setHours(0, 0, 0, 0);

    if (deliveryDate.getTime() !== tomorrow.getTime()) {
      return false;
    }

    const date = new Date();
    const timeNowInMilliseconds = (date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()) * 1000;

    return cutOffTime && timeNowInMilliseconds > cutOffTime;
  });

  readonly #computedOrderStatus = computed(() =>
    this.#isOrderLessThanSupplierMinimumLimit()
      ? $localize`:@@statusRejected:Rejected`
      : $localize`:@@itemDelayed:Delayed`,
  );

  protected readonly order = this.#store.selectSignal(OrdersState.currentOrder);
  protected readonly currency = this.#store.selectSignal(SettingsState.currency);
  protected readonly currencyPrecision = this.#store.selectSignal(SettingsState.currencyPrecision);
  protected readonly utcOffset = this.#store.selectSignal(SettingsState.utcOffset);
  protected readonly ianaTimeZone = this.#store.selectSignal(SettingsState.ianaTimeZone);
  protected readonly pushToInventorySettings = this.#store.selectSignal(SettingsState.pushToInventorySettings);
  protected readonly isLoading = toSignal(
    this.#store.select(actionsExecuting([OrdersCreate, OrdersUpdate])).pipe(map(Boolean)),
  );

  protected readonly canSubmitOrder = toSignal(inject(OrdersPermissionsStrategy.Submit).isAllowed());
  protected readonly channelItems = signal<InventoryChannelItem[]>([]);
  protected readonly channel = computed(() => this.#routeData()?.channel as Channel);
  protected readonly minimumDeliveryDate = computed(() => getDateInTimeZone(new Date(), this.ianaTimeZone()));
  protected readonly isFooterDisabled = computed(
    () => this.channelItems().length < 1 || this.channelItems().some(({ quantity }) => !quantity) || this.isLoading(),
  );

  protected readonly totalPrice = computed(() =>
    this.channelItems().reduce<number>((acc, { quantity, price }) => acc + quantity * price, 0),
  );

  protected readonly totalQuantity = computed(() =>
    this.channelItems().reduce<number>((acc, { quantity }) => acc + quantity, 0),
  );

  protected readonly isCk = computed(() => {
    const channel = this.channel();

    if (channel) {
      return (
        channel.partyInfo?.supplier.type === SupplierType.centralKitchen && !channel.partyInfo?.supplier?.isExposed
      );
    }

    return false;
  });

  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  protected readonly orderStatus = OrderStatus;

  constructor() {
    super({
      comment: [null],
      deliveryDate: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.#placeOrderService.navigatedToReview.set(true);
    this.fetchOrderIfExists();
    this.patchForm();
    this.setChannelItemsFromBasket();
  }

  protected onQuantityChange(cellData: InventoryChannelItem, quantity: number): void {
    this.channelItems.update(channelItems =>
      channelItems.map(channelItem => ({
        ...channelItem,
        quantity: Number(
          (channelItem.id === cellData.id ? quantity : channelItem.quantity).toFixed(DEFAULT_QUANTITY_PRECISION),
        ),
      })),
    );
  }

  protected onRemoveItem(value: string): void {
    this.channelItems.update(channelItems => channelItems.filter(({ id }) => id !== value));
  }

  protected goBack(): void {
    this.#placeOrderService.setBasketItems(this.channelItems());
    this.#store.dispatch(
      new Navigate(['..', 'place'], null, {
        relativeTo: this.#route,
        queryParamsHandling: 'preserve',
      }),
    );
  }

  protected onSubmit(): void {
    const id = this.#orderId();

    if (id && !this.#isRepeat()) {
      this.update$({ id, status: OrderUpdateStatus.submitted })
        .pipe(
          first(),
          tap(() =>
            this.#snackbar.open($localize`:@@placeOrder.review.submitSuccess:Order has been submitted successfully`, {
              variant: 'success',
            }),
          ),
        )
        .subscribe(() => {
          this.#placeOrderService.clearBasket();
          this.navigateToOrders();
        });
    } else {
      this.checkSupplierShortageOrDelay$()
        .pipe(
          first(),
          switchMap(() => this.create$({ status: OrderStatus.Submitted })),
          tap(() =>
            this.#snackbar.open($localize`:@@placeOrder.review.submitSuccess:Order has been submitted successfully`, {
              variant: 'success',
            }),
          ),
        )
        .subscribe(() => {
          this.#placeOrderService.clearBasket();
          this.navigateToOrders();
        });
    }
  }

  protected onDraft(): void {
    this.checkSupplierShortageOrDelay$()
      .pipe(
        first(),
        switchMap(() => this.create$({ status: OrderStatus.Draft })),
        tap(() =>
          this.#snackbar.open($localize`:@@placeOrder.review.draftSuccess:Order has been drafted successfully`, {
            variant: 'success',
          }),
        ),
      )
      .subscribe(() => {
        this.#placeOrderService.clearBasket();
        this.navigateToOrders();
      });
  }

  protected onUpdate(): void {
    this.update$({ id: this.#orderId(), status: OrderUpdateStatus.draft })
      .pipe(
        first(),
        tap(() =>
          this.#snackbar.open($localize`:@@placeOrder.updateSuccess:Order has been updated successfully`, {
            variant: 'success',
          }),
        ),
      )
      .subscribe(() => {
        this.#placeOrderService.clearBasket();
        this.navigateToOrders();
      });
  }

  private fetchOrderIfExists(): void {
    const id = this.#orderId();

    if (id) {
      this.#store.dispatch(new OrdersGet({ id }));
    }
  }

  private setChannelItemsFromBasket(): void {
    this.channelItems.set(this.#placeOrderService.basket());
  }

  private patchForm(): void {
    effect(
      () => {
        const order = this.order();
        const ianaTimeZone = this.ianaTimeZone();
        const tomorrow = getDateInTimeZone(new Date(), ianaTimeZone);

        tomorrow.setDate(tomorrow.getDate() + 1);

        this.reconfigure('deliveryDate', {
          value: tomorrow,
          validators: [Validators.required, InputValidators.minDate(this.minimumDeliveryDate())],
        });

        if (order && !this.#isRepeat()) {
          this.form.patchValue({
            comment: order.comment,
            deliveryDate: this.getDateInRetailerTimeZone(new Date(order.deliveryDate)),
          });
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  @Span()
  private create$({ status }: { readonly status: OrderStatus }): Observable<unknown> {
    const { id, supplierId, retailerId } = this.channel();
    const deliveryDate = this.getRetailerTimeZoneShiftedDate(this.getValue('deliveryDate'));
    const comment = this.getValue('comment');
    const orderedChannelItems = this.channelItems()
      .filter(({ quantity }) => quantity > 0)
      .map(({ id, quantity }) => ({ productId: id, quantity }));

    return this.#store.dispatch(
      new OrdersCreate({
        supplierId,
        retailerId,
        status,
        comment,
        deliveryDate,
        channelId: id,
        ordered: orderedChannelItems,
      }),
    );
  }

  @Span()
  private update$({ id, status }: { readonly id: string; readonly status: OrderUpdateStatus }): Observable<unknown> {
    const deliveryDate = this.getRetailerTimeZoneShiftedDate(this.getValue('deliveryDate'));
    const comment = this.getValue('comment');
    const orderedChannelItems = this.channelItems()
      .filter(({ quantity }) => quantity > 0)
      .map(({ id, quantity }) => ({ productId: id, quantity }));

    return this.#store.dispatch(
      new OrdersUpdate({
        id,
        body: {
          comment,
          deliveryDate,
          type: status,
          ordered: orderedChannelItems,
        },
      }),
    );
  }

  private checkSupplierShortageOrDelay$(): Observable<{
    preConfirmCheck: boolean;
  } | null> {
    return this.#isOrderAfterSupplierCutOffTime() || this.#isOrderLessThanSupplierMinimumLimit()
      ? this.#dialog.openDialog(ConfirmDialogWithIconComponent, {
          title: $localize`:@@placeOrder.review.actionTitle:Order Might Be ${this.#computedOrderStatus()}`,
          message: this.#isOrderLessThanSupplierMinimumLimit()
            ? $localize`:@@placeOrder.review.totalBelowMinimum:Your order total is below the supplier's minimum order limit. Please contact the supplier to avoid rejection.`
            : $localize`:@@placeOrder.review.afterCutOff:You are placing an order after the supplier's cut-off time. Please contact the supplier to avoid delays`,
          confirmText: $localize`:@@placeOrder.review.confirm:Place Order Anyway`,
        }).confirm
      : of(null);
  }

  private navigateToOrders(): void {
    this.#store.dispatch(new Navigate(['/orders']));
  }

  private getDateInRetailerTimeZone(date: Date): Date {
    return getDateInTimeZone(date, this.ianaTimeZone());
  }

  private getRetailerTimeZoneShiftedDate(date: Date): Date {
    return getShiftedDate(getDateWithDaysDifference(date, this.ianaTimeZone()), this.utcOffset());
  }
}
